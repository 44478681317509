import {
  FormEvent,
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import FocusTrap from 'focus-trap-react';
import { Portal } from 'react-portal';
import classNames from 'classnames';
import { fuseSearch } from 'api/searchIndex';
import { useOTSTranslation } from 'i18n/useOTSTranslation';
import { Spinner } from 'components/Spinner';
import {
  ISearchNewsResult, ISearchResult, SearchCategory,
} from 'api/home/types';
import { I18nKey } from 'i18n/types';
import SearchResultsColumn from 'components/home/SearchPopup/SearchResultsColumn';
import SearchNewsColumn from 'components/home/SearchPopup/SearchNewsColumn';
import SearchBar from 'components/home/SearchPopup/SearchBar';
import { fetchNewsSearch } from 'api/home/static';
import { sortByKeyExisting } from 'util/sortByKeyExisting';

import styles from './styles.module.scss';

export const labelMap: Record<SearchCategory, I18nKey['common']> = {
  [SearchCategory.resorts]: 'searchResults.resorts',
  [SearchCategory.regions]: 'searchResults.regions',
  [SearchCategory.news]: 'searchResults.news',
  [SearchCategory.other]: 'common.dash',
};

export const SearchPopup = ({
  closePopup,
  query,
}: {
  closePopup: () => void,
  query?: string | string[] | undefined,
}) => {
  const { t } = useOTSTranslation('common');
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [newsLoading, setNewsLoading] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<ISearchResult>();
  const [newsSearchResult, setNewsSearchResult] = useState<ISearchNewsResult>();
  const [searchResultFor, setSearchResultFor] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const debounce = useRef<NodeJS.Timeout | null>(null);
  const [focusedIndex, setFocusedIndex] = useState<number>(0);
  const [shouldFollowFocusedLink, setShouldFollowFocusedLink] = useState(false);

  const getSearchResult = useCallback(async (e?:FormEvent<HTMLFormElement> | undefined, forceQuery?: string) => {
    if (e) {
      e.preventDefault();
    }

    toggleLoading(true);
    if (forceQuery) {
      setSearchValue(forceQuery);
    }
    const searchResponse = await fuseSearch(forceQuery || searchValue, undefined, t);

    setSearchResult(searchResponse);
    setSearchResultFor(forceQuery || searchValue);

    toggleLoading(false);
  }, [searchResult, searchValue]);

  const getNewsResult = useCallback(async (forceQuery?: string) => {
    setNewsLoading(true);

    const news = await fetchNewsSearch(forceQuery || searchValue);
    setNewsSearchResult(news);

    setNewsLoading(false);
  }, [newsSearchResult, searchValue]);

  const handleInputChange = useCallback((e: React.SyntheticEvent) => {
    if (debounce.current) {
      clearInterval(debounce.current);
    }

    const target = e.target as HTMLInputElement;
    const val = target.value;
    setSearchValue(val);

    debounce.current = setTimeout(() => {
      getSearchResult(undefined, val);
      getNewsResult(val);
    }, 200);
  }, [searchValue, getSearchResult]);

  useEffect(() => {
    if (query) {
      const newQuery = Array.isArray(query) ? query[0] : query;
      setSearchValue(newQuery);
      getSearchResult(undefined, newQuery);
      getNewsResult(newQuery);
    }
  }, [query]);

  useEffect(() => {
    document.body.classList.add('search-overlay');

    return () => {
      closePopup();
      document.body.classList.remove('search-overlay');
    };
  }, []);

  const filteredSearchResults = useMemo(() => {
    const others = searchResult?.results.other || [];
    const resorts = searchResult?.results.resorts || [];
    const regions = searchResult?.results.regions || [];
    const [showFirstResorts, showLastResorts] = sortByKeyExisting(resorts, 'resort.domain');
    const [showFirstRegions, showLastRegions] = sortByKeyExisting(regions, 'region.domain');

    return showFirstRegions.concat(others, showFirstResorts, showLastRegions, showLastResorts);
  }, [searchResult, searchResultFor]);

  const handleKeyDown = useCallback((e:React.KeyboardEvent<HTMLDivElement> | undefined) => {
    if (e) {
      if (e.code === 'Escape') {
        closePopup();
        return;
      }
      if (e.code === 'Enter') {
        setShouldFollowFocusedLink(true);
        return;
      }

      const prevFocusedIndex = focusedIndex;
      let newFocusedIndex = focusedIndex;
      if (e.code === 'ArrowUp') {
        newFocusedIndex -= 1;
      } if (e.code === 'ArrowDown') {
        newFocusedIndex += 1;
      }

      const isNewFocusIndexWithinBounds = newFocusedIndex >= 0 && newFocusedIndex < filteredSearchResults.length;

      if (!isNewFocusIndexWithinBounds) {
        const isPrevFocusIndexWithinBounds = prevFocusedIndex >= 0 && prevFocusedIndex < filteredSearchResults.length;

        if (!isPrevFocusIndexWithinBounds) {
          newFocusedIndex = 0;
        } else {
          newFocusedIndex = prevFocusedIndex;
        }
      }

      setFocusedIndex(newFocusedIndex);
    }
  }, [focusedIndex, filteredSearchResults.length, setFocusedIndex]);

  return (
    <Portal>
      <FocusTrap>
        <div
          role="dialog"
          aria-hidden="true"
          tabIndex={-1}
          className={styles.searchPopup}
          onKeyDown={handleKeyDown}
        >
          <div className={styles.bg} />
          <SearchBar
            onSubmit={getSearchResult}
            value={searchValue}
            onChange={handleInputChange}
            onClose={closePopup}
          />
          <section className={classNames(styles.resultSearch)}>
            <div className="container">
              {!searchResult
                && !isLoading
                && !newsSearchResult
                && <h2 className={styles.helpText}>{t('searchResults.helptext')}</h2>}
              <div className={classNames(styles.columns, isLoading && styles.isLoading)}>
                {isLoading && <Spinner />}
                {!isLoading && filteredSearchResults.length !== 0 && (
                  <SearchResultsColumn
                    label={`${t(labelMap['regions' as SearchCategory])} & ${t(labelMap['resorts' as SearchCategory])}`}
                    list={filteredSearchResults}
                    query={searchResultFor}
                    focusedIndex={focusedIndex}
                    shouldFollowFocusedLink={shouldFollowFocusedLink}
                  />
                )}
                {!isLoading && searchResult && (
                  <SearchNewsColumn
                    label={t('searchResults.news')}
                    loading={newsLoading}
                    list={newsSearchResult?.results}
                    query={searchResultFor}
                  />
                )}
              </div>
            </div>
          </section>
        </div>
      </FocusTrap>
    </Portal>
  );
};
