import { locale } from 'i18n/constants';
import { ELocale } from 'i18n/localeEnum';
import { IS_SUMMER } from 'ots-constants';

const SUMMER_LANGUAGES = [ELocale.EN_US, ELocale.EN_GB, ELocale.FR_FR, ELocale.DE_DE, ELocale.IT_IT, ELocale.PL_PL];

export const useShouldAdjustForSummer = () => {
  try {
    return IS_SUMMER && SUMMER_LANGUAGES.includes(locale as ELocale);
  } catch {
    console.error('useShouldAdjustForSummer error! Probably unspecified locale!');
    return false;
  }
};
