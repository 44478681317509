import React from 'react';
import { ICategoryNews } from 'api/home/types';
import SearchLink from 'components/home/SearchPopup/SearchLink';

import styles from './styles.module.scss';

interface INewsCategoryProps {
  categories: Array<ICategoryNews> | ICategoryNews | undefined | null
  searchResultFor: string
}

const NewsCategories: React.FC<INewsCategoryProps> = ({ categories, searchResultFor }) => {
  if (!categories) {
    return null;
  }

  const tags: Array<ICategoryNews> = Array.isArray(categories) ? categories : [categories];

  return (
    <>
      {tags.map((tag) => (
        <SearchLink
          key={tag.uuid}
          className={styles.tag}
          query={searchResultFor}
          href={tag.link ?? ''}
        >
          <span>{tag.title}</span>
        </SearchLink>
      ))}
    </>
  );
};

export default React.memo(NewsCategories);
