import { ISearchResultNews } from 'api/home/types';
import classNames from 'classnames';
import { Spinner } from 'components/Spinner';
import { htmlDecode } from 'util/htmlDecode';
import NewsCategories from 'components/home/SearchPopup/NewsCategories';
import NoResutls from './NoResults';
import SearchLink from './SearchLink';

import styles from './styles.module.scss';

interface ISearchNewsColumnProps {
  label: string
  list: ISearchResultNews[] | undefined
  loading: boolean
  query: string
}

const SearchNewsColumn: React.FC<ISearchNewsColumnProps> = ({
  label, list, loading, query,
}) => (
  <div className={classNames(styles.category, { [styles.loading]: loading })}>
    <h2>{label}</h2>
    {loading && <Spinner />}
    <div className={styles.list}>
      {!loading && !list?.length && (<NoResutls />)}
      {
        !loading && list?.map((item) => (
          <div
            key={item.uuid}
            className={styles.item}
          >
            <SearchLink
              href={item.news.link ?? ''}
              query={query}
            >
              {htmlDecode(item.news.title)}
            </SearchLink>
            <NewsCategories categories={item.news.categories} searchResultFor={query} />
          </div>
        ))
      }
    </div>
  </div>
);

export default SearchNewsColumn;
